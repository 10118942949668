import React from "react"
import { BackButton } from '../components/Icons'
import { Link } from 'gatsby'

const Page = () => {

    const textStyle = {margin: 'auto', width: '75%', textAlign: 'left'}
    const bodyStyle = {margin: 'auto', width: '75%', textAlign: 'left', overflowY: 'auto', maxHeight: '70%'}

    return(
        <div className="base-layout">
            <div className="title">
                resources
            </div>
            <div className="medium-text" style = {bodyStyle} >
                The approach to decision-making used in decision.ninja is based on Marshall Rosenberg's theory of Nonviolent Communication.
                <br/><br/>
                Nonviolent Communication was conceived as a means of resolving conflicts between individuals or communities - decision.ninja uses these ideas to help resolve our own internal conflicts, by seeking clarity about which of our needs are at stake.
                <br/><br/>
                You can find our more about Marshall Rosenberg and Nonviolent Communication at the following websites:
                <ul>
                    <li><a href="https://www.nonviolentcommunication.com/"> Nonviolent<br/>Communication.com </a></li>                
                    <li><a href="https://www.cnvc.org/node/6856">The Center for Nonviolent Communication</a></li>
                </ul>
                
                If you are interested in discussion about how the theory is applied within decision.ninja, you will find others who are interested to discuss this topic on the <a href='https://discord.gg/wHrU2afRFn'>decision.ninja discord server</a>
            </div>
            <BackButton
                text="back"/>
        </div>
    )
}

export default Page